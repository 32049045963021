import { IS_BROWSER } from '$fresh/runtime.ts';
import getPasswordStrength from 'password_meter';
import { ForwardedRef, forwardRef, RefObject, useEffect, useRef, useState } from 'preact/compat';

const PasswdChecker = forwardRef(function (
    props:
        & (
            | ({ passwd: string } & { field?: never })
            | ({ passwd?: never } & {
                field: RefObject<HTMLInputElement> | string;
            })
        )
        & { className?: string },
    ref?: ForwardedRef<HTMLDivElement>,
) {
    if (!IS_BROWSER) return <></>;

    ref ??= useRef<HTMLDivElement>(null);

    const passwd = ('passwd' in props
        ? props.passwd
        : (typeof props.field == 'string'
            ? (document.getElementById(props.field) as HTMLInputElement)
                ?.value
            : props.field?.current?.value)) ?? '';
    const [strength, setStrength] = useState(getPasswordStrength(passwd));

    if (typeof props.field != 'undefined') {
        if (typeof props.field == 'string') {
            setInterval(() => {
                setStrength(
                    getPasswordStrength(
                        (document.getElementById(
                            props.field as string,
                        ) as HTMLInputElement)
                            ?.value ??
                            '',
                    ),
                );
            }, 100);
        } else {
            useEffect(() => {
                setStrength(
                    getPasswordStrength(
                        (props.field as RefObject<HTMLInputElement>).current
                            ?.value ??
                            '',
                    ),
                );
            }, [props.field.current?.value]);
        }
    }

    return (
        <div
            ref={ref}
            className={`flex w-full items-center ${props.className ?? ''}`}
            tabIndex={-1}
        >
            <div className='flex shrink-0 relative bg-gray-500 rounded-full w-5/6 h-1.5'>
                <div
                    className={'flex relative w-full rounded-full' +
                        (strength > 66 ? ' bg-green-500' : '')}
                >
                    <div
                        className={'flex relative w-2/3 rounded-full' +
                            (strength > 33 ? ' bg-yellow-500' : '')}
                    >
                        <div
                            className={'flex relative w-1/2 rounded-full' +
                                (passwd.length > 0 ? ' bg-red-500' : '')}
                        >
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={'ml-3 text-sm' +
                    (passwd.length > 0 ? '' : ' invisible')}
            >
                {strength > 66
                    ? <p className='text-green-500'>Strong</p>
                    : strength > 33
                    ? <p className='text-yellow-500'>Moderate</p>
                    : <p className='text-red-500'>Weak</p>}
            </div>
        </div>
    );
});

export default PasswdChecker;
